import { tokenKeys, ITokenKeyParams } from './tokenKeys';

export interface IInsuranceRequestKeyParams extends ITokenKeyParams {
  insuranceRequestID: string;
}

export const insuranceRequestKeys = {
  root: (params: IInsuranceRequestKeyParams) =>
    [...tokenKeys.root(params), params.insuranceRequestID] as const,
};

export function parseInsuranceRequestKey<
  T extends keyof typeof insuranceRequestKeys,
>(
  queryKey: ReturnType<typeof insuranceRequestKeys[T]>,
): IInsuranceRequestKeyParams {
  const [, token, insuranceRequestID] = queryKey;

  return { token, insuranceRequestID };
}
