import { Checkbox, ContentBox, KeyBenefits } from '@a2755/ui-components';
import classNames from 'classnames';
import { useContext, useState } from 'react';
import styles from './Terms.module.scss';
import stepStyles from '../../StepProvider.module.scss';
import { StepNavigationButtons } from '../../../components/StepNavigationButtons';
import { InsuranceSubHeading } from '../../../components/Insurance/InsuranceHeading';
import { StepContext } from '../../store/context';
import ValidationContentBox from '../../../components/ValidationContentBox/ValidationContentBox';
import { getValidState } from '../../../utils/getValidState';
import { getTranslation } from '../../../translations/getTranslation';
import { NavWrapper } from '../../../components/NavWrapper/NavWrapper';

export const Terms = () => {
  const { dispatch, state } = useContext(StepContext);
  const { t } = getTranslation();

  const {
    steps: { terms: stepData },
  } = state;

  const [triedContinue, setTriedContinue] = useState(false);

  function handleNext() {
    setTriedContinue(true);
    if (stepData.hasConfirmedEligibility && stepData.hasConfirmedTerms) {
      dispatch({
        type: 'Set step data',
        scope: { parentType: stepData.name },
        payload: { ...stepData, state: 'completed' },
      });
      dispatch({
        type: 'Set step',
        scope: { parentType: 'step' },
        payload: 'powerOfAttorney',
      });
    }
  }

  function handleBack() {
    dispatch({
      type: 'Set step data',
      scope: { parentType: stepData.name },
      payload: { ...stepData, state: 'touched' },
    });
    dispatch({
      type: 'Set step',
      scope: { parentType: 'step' },
      payload: 'selectCoverage',
    });
  }

  return (
    <div>
      <div>
        <InsuranceSubHeading>{stepData.title}</InsuranceSubHeading>
        <ContentBox
          classes={{
            root: classNames(stepStyles.contentContainer),
          }}
        >
          <div className={styles.content}>
            <div className={styles.keyBenefits}>
              <KeyBenefits
                benefits={[
                  t('18-65 years old').toString(),
                  t('I live permanently in Finland').toString(),
                  t(
                    'I am on a permanent employment relationship and work at least 16 hours a week',
                  ).toString(),
                  t(
                    'I am 100 % capable and I am not aware of future lay-off or dismissal',
                  ).toString(),
                  t(
                    'I am not aware of any illness or chronic condition',
                  ).toString(),
                ]}
              />
            </div>
            <ValidationContentBox
              active={
                stepData.hasConfirmedEligibility && stepData.hasConfirmedTerms
              }
              invalid={
                triedContinue &&
                !(
                  stepData.hasConfirmedEligibility && stepData.hasConfirmedTerms
                )
              }
            >
              <Checkbox
                name="hasConfirmedEligibility"
                onChange={(e) =>
                  dispatch({
                    type: 'Set step data',
                    scope: { parentType: stepData.name },
                    payload: { hasConfirmedEligibility: e.target.checked },
                  })
                }
                errors={getValidState(
                  stepData.hasConfirmedEligibility,
                  triedContinue,
                  t('To proceed, you must be eligible').toString(),
                )}
                classes={{ root: styles.checkbox }}
                value={stepData.hasConfirmedEligibility}
              >
                {t('I will meet the above conditions')}
              </Checkbox>
              <Checkbox
                name="hasConfirmedTerms"
                onChange={(e) =>
                  dispatch({
                    type: 'Set step data',
                    scope: { parentType: stepData.name },
                    payload: { hasConfirmedTerms: e.target.checked },
                  })
                }
                errors={getValidState(
                  stepData.hasConfirmedTerms,
                  triedContinue,
                  t('To proceed, you must accept the terms').toString(),
                )}
                value={stepData.hasConfirmedTerms}
              >
                {t('I understand that security insurance is valid despite')}
              </Checkbox>
            </ValidationContentBox>
          </div>
        </ContentBox>
      </div>
      <NavWrapper>
        <StepNavigationButtons
          classes={{
            buttonContainer: styles.buttonContainer,
          }}
          backButtonText={t('Go back').toString()}
          backButton={{
            width: 'full-width',
            onClick: () => handleBack(),
            classes: { root: styles.button },
          }}
          continueButtonText={t('Next').toString()}
          continueButton={{
            width: 'full-width',
            onClick: () => handleNext(),
            classes: { root: styles.button },
          }}
        />
      </NavWrapper>
    </div>
  );
};
