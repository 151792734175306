import { tokenKeys, ITokenKeyParams } from './tokenKeys';

export interface IInsuranceCustomerKeyParams extends ITokenKeyParams {
  insurancePolicyID: string;
  customerID: string;
  marketCountry: string;
}

export const insuranceCustomerKeys = {
  root: (params: IInsuranceCustomerKeyParams) =>
    [
      ...tokenKeys.root(params),
      'country',
      params.marketCountry,
      'customer',
      params.customerID,
      'insurancePolicy',
      params.insurancePolicyID,
      'customer',
    ] as const,
};

export function parseInsuranceCustomerKey<
  T extends keyof typeof insuranceCustomerKeys,
>(
  queryKey: ReturnType<typeof insuranceCustomerKeys[T]>,
): IInsuranceCustomerKeyParams {
  const [, token, , marketCountry, , customerID, , insurancePolicyID] =
    queryKey;

  return { token, insurancePolicyID, customerID, marketCountry };
}
