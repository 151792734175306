import { useContext } from 'react';
import { QueryFunctionContext, useQuery } from 'react-query';
import { ApplicationContext } from '../../store/context';
import { getInsurancePolicy } from '../api/getInsurancePolicy';
import {
  insurancePolicyKeys,
  parseInsurancePolicyKey,
} from './cache-keys/insurancePolicyKeys';

function insurancePolicyQuery({
  queryKey,
}: QueryFunctionContext<ReturnType<typeof insurancePolicyKeys['root']>>) {
  const { token, insurancePolicyID } = parseInsurancePolicyKey(queryKey);
  return getInsurancePolicy(token, insurancePolicyID);
}

export function useInsurancePolicy(id?: string) {
  const { state } = useContext(ApplicationContext);
  const cacheKey = insurancePolicyKeys.root({
    token: state.user.token,
    insurancePolicyID: id ?? '',
  });

  return useQuery(cacheKey, insurancePolicyQuery, {
    enabled: !!id,
  });
}
