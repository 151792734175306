import { tokenKeys, ITokenKeyParams } from './tokenKeys';

export interface IInsurancePolicyKeyParams extends ITokenKeyParams {
  insurancePolicyID: string;
}

export const insurancePolicyKeys = {
  root: (params: IInsurancePolicyKeyParams) =>
    [...tokenKeys.root(params), params.insurancePolicyID] as const,
};

export function parseInsurancePolicyKey<
  T extends keyof typeof insurancePolicyKeys,
>(
  queryKey: ReturnType<typeof insurancePolicyKeys[T]>,
): IInsurancePolicyKeyParams {
  const [, token, insurancePolicyID] = queryKey;

  return { token, insurancePolicyID };
}
