import { useContext } from 'react';
import { QueryFunctionContext, useQuery } from 'react-query';
import { Status } from '../../models/insurance-request';
import { ApplicationContext } from '../../store/context';
import { getInsuranceRequest } from '../api/getInsuranceRequest';
import {
  insuranceRequestKeys,
  parseInsuranceRequestKey,
} from './cache-keys/insuranceRequestKeys';

function insuranceRequestQuery({
  queryKey,
}: QueryFunctionContext<ReturnType<typeof insuranceRequestKeys['root']>>) {
  const { token, insuranceRequestID } = parseInsuranceRequestKey(queryKey);
  return getInsuranceRequest(token, insuranceRequestID);
}

export function useInsuranceRequest(id?: string) {
  const { state } = useContext(ApplicationContext);
  const cacheKey = insuranceRequestKeys.root({
    token: state.user.token,
    insuranceRequestID: id ?? '',
  });

  return useQuery(cacheKey, insuranceRequestQuery, {
    enabled: !!id,
    // refetch every second until we have an EmbedURL
    refetchInterval: (data) => (data?.Status == Status.New ? 1_000 : false),
    keepPreviousData: true,
  });
}
