export interface IMagicTokenKeyParams {
  magicToken: string | null;
}

export const magicTokenKeys = {
  root: (params: IMagicTokenKeyParams) =>
    ['magicToken', params.magicToken] as const,
  magicLoanApplication: (params: IMagicTokenKeyParams) =>
    [...magicTokenKeys.root(params), 'loanApplication'] as const,
};

export function parseMagicTokenKey<T extends keyof typeof magicTokenKeys>(
  queryKey: ReturnType<typeof magicTokenKeys[T]>,
) {
  const [, magicToken] = queryKey;

  return { magicToken };
}
