import { Header } from '@a2755/ui-components';
import { useCallback, useReducer } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Outlet } from 'react-router-dom';
import { AuthProvider } from '../../components/AuthProvider';
import { Error as ErrorPage } from '../../components/Error';
import { WebSocketProvider } from '../../components/WebSocket';
import { getInitialAppContextState } from '../../utils/storedState';
import { ApplicationContext } from '../../store/context';
import { reducer } from '../../store/reducer';
import styles from './Main.module.scss';
import { InsuranceProvider } from '../../components/InsuranceProvider/InsuranceProvider';

const queryClient = new QueryClient();

export function Main() {
  const search = new URLSearchParams(window.location.search);
  const [state, originalDispatch] = useReducer(
    reducer,
    getInitialAppContextState(search),
  );

  const dispatch: typeof originalDispatch = useCallback(
    (action) => {
      if (process.env.NODE_ENV !== 'production') {
        console.debug('Action dispatched:', action);
      }

      originalDispatch(action);
    },
    [originalDispatch],
  );

  return (
    <ApplicationContext.Provider value={{ state, dispatch }}>
      <ErrorPage>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <WebSocketProvider>
              <InsuranceProvider>
                <Header
                  showOpeningBar={false}
                  compact_opening_hours_text=""
                  country="fi"
                  full_opening_hours_text=""
                  header_links={[]}
                  helper_text=""
                  telephone=""
                  timezone="Europe/Helsinki"
                  working_hours={[]}
                  focusMode={false}
                />
                <div className={styles.container}>
                  <Outlet />
                </div>
                <ReactQueryDevtools initialIsOpen={false} />
              </InsuranceProvider>
            </WebSocketProvider>
          </AuthProvider>
        </QueryClientProvider>
      </ErrorPage>
    </ApplicationContext.Provider>
  );
}
