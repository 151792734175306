import { URLSearchParams } from 'url';
import {
  initialState as appInitialState,
  State as AppState,
} from '../store/reducer';
import {
  initialState as stepInitialState,
  State as StepState,
} from '../StepProvider/store/reducer';

function crateStateKey() {
  if (window.location.protocol === 'https' && 'crypto' in window) {
    return window.crypto.randomUUID();
  }

  return (Math.random() + 1).toString(36).substring(2);
}

export function getInitialAppContextState(search: URLSearchParams) {
  const state = search.get('state');

  if (!state) return appInitialState;

  const item = sessionStorage.getItem(state);

  if (!item) return appInitialState;

  return JSON.parse(item).applicationContext as AppState;
}

export function getInitialStepContextState(search: URLSearchParams) {
  const state = search.get('state');

  if (!state) return stepInitialState;

  const item = sessionStorage.getItem(state);

  if (!item) return stepInitialState;

  return JSON.parse(item).stepContext as StepState;
}

export interface IStoredState {
  applicationContext: AppState;
  stepContext: StepState;
}

export function create() {
  const key = crateStateKey();

  const url = new URL(window.location.href);
  url.searchParams.delete('id');
  url.searchParams.set('state', key);

  return { url: url.toString(), key };
}

export function get(url: URL) {
  const key = url.searchParams.get('state');

  if (!key) throw new Error(`Missing state param`);

  return { url: url.toString(), key };
}

export function store(key: string, state: IStoredState) {
  sessionStorage.setItem(key, JSON.stringify(state));
}
