import { Insurance } from '../../models';
import { API_URL } from '../../utils/config';

export async function getInsurancePolicy(token: string, id: string) {
  const url = new URL(`/insurance/${id}`, API_URL);
  const response = await fetch(url, {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  });
  if (!response.ok) {
    throw new Error(`Network response was not OK: ${response.status}`);
  }
  return (await response.json()) as Insurance.IInsurancePolicy;
}
