export interface ITokenKeyParams {
  token: string;
}

export const tokenKeys = {
  root: (params: ITokenKeyParams) => ['token', params.token] as const,
  lenders: (params: ITokenKeyParams) =>
    [...tokenKeys.root(params), 'lenders'] as const,
};

export function parseTokenKey<T extends keyof typeof tokenKeys>(
  queryKey: ReturnType<typeof tokenKeys[T]>,
) {
  const [, token] = queryKey;

  return { token };
}
