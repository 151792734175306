import { ReactNode, useCallback, useContext, useEffect } from 'react';
import {
  useLoanApplication,
  useLoanApplicationFromMagicToken,
} from '../../hooks/queries/useLoanApplication';
import { ApplicationContext } from '../../store/context';
import { PageSpinner } from '../Spinner';
import { LoanApplication } from '../../models';

interface ILogin {
  children: ReactNode;
}

function useAuth() {
  const { state, dispatch } = useContext(ApplicationContext);
  const search = new URLSearchParams(window.location.search);
  const magicToken = search.get('m');
  const hash = new URLSearchParams(window.location.hash.replace(/^#/, ''));
  const token = hash.get('token');
  const applicationID = search.get('id');

  const loanApplicationFromMagic = useLoanApplicationFromMagicToken(
    state.user.token ? null : magicToken,
  );

  const loanApplication = useLoanApplication(token, applicationID);

  const onLoaded = useCallback(
    (jwt: string, application: LoanApplication.ILoanApplication) => {
      dispatch({
        type: 'Set auth',
        scope: { parentType: 'user' },
        payload: {
          jwt,
          application,
        },
      });
    },
    [dispatch],
  );

  useEffect(() => {
    const data = loanApplicationFromMagic.data;
    if (data) {
      onLoaded(data.JWT, data);
    }
  }, [loanApplicationFromMagic.data, onLoaded]);

  useEffect(() => {
    const data = loanApplication.data;
    if (data && token) {
      onLoaded(token, data);
    }
  }, [token, loanApplication.data, onLoaded]);

  return {
    error: loanApplication.error || loanApplicationFromMagic.error,
    isLoading: loanApplication.isLoading || loanApplicationFromMagic.isLoading,
  };
}

export function AuthProvider({ children }: ILogin) {
  const { error, isLoading } = useAuth();
  const { state } = useContext(ApplicationContext);

  if (error) {
    window.location.href = 'https://www.axolaina.fi/';
    return <PageSpinner />;
  }

  if (isLoading || !state.user.token) {
    return <PageSpinner />;
  }

  return <>{children}</>;
}
