import { useContext } from 'react';
import { QueryFunctionContext, useQuery } from 'react-query';
import { ApplicationContext } from '../../store/context';
import { findInsurancePayment } from '../api/findInsurancePayment';
import {
  insuranceCustomerKeys,
  parseInsuranceCustomerKey,
} from './cache-keys/insuranceCustomerKeys';

function insurancePaymentsQuery({
  queryKey,
}: QueryFunctionContext<ReturnType<typeof insuranceCustomerKeys['root']>>) {
  const { token, insurancePolicyID, marketCountry, customerID } =
    parseInsuranceCustomerKey(queryKey);
  return findInsurancePayment(token, {
    insuranceID: insurancePolicyID,
    customerID,
    marketCountry,
  });
}

export function useInsurancePayments() {
  const { state } = useContext(ApplicationContext);

  const cacheKey = insuranceCustomerKeys.root({
    token: state.user.token,
    insurancePolicyID: state.insurancePolicyReference?.PolicyID ?? '',
    customerID: state.user.customerID ?? '',
    marketCountry: state.application?.MarketCountry ?? '',
  });

  return useQuery(cacheKey, insurancePaymentsQuery, {
    enabled: !!(
      state.insurancePolicyReference &&
      state.user.customerID &&
      state.application
    ),
  });
}
