import { InsurancePayment } from '../../models';

export async function createInsurancePayment(
  token: string,
  request: InsurancePayment.CreatePaymentRequest,
) {
  const url = new URL(`/insurance-payment`, BASE_URL);
  const response = await fetch(url, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify(request),
  });
  if (!response.ok) {
    throw new Error(`Network response was not OK: ${response.status}`);
  }
  return (await response.json()) as InsurancePayment.IPayment;
}

const BASE_URL = process.env.REACT_APP_API_URL;
