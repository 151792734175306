import { useContext } from 'react';
import { QueryFunctionContext, useQuery } from 'react-query';
import { ApplicationContext } from '../../store/context';
import { IPerson } from '../../models/person';
import { parsePersonKey, personKeys } from './cache-keys/personKeys';

const BASE_URL = process.env.REACT_APP_API_URL;

async function fetchPerson(token: string, personID: string): Promise<IPerson> {
  const url = new URL(`/person/${personID}`, BASE_URL);
  try {
    const response = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: {
        Authorization: token,
      },
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  } catch {
    throw new Error('Failed to fetch person');
  }
}

function personQuery({
  queryKey,
}: QueryFunctionContext<ReturnType<typeof personKeys['root']>>) {
  const { token, personID } = parsePersonKey(queryKey);
  return personID ? fetchPerson(token, personID) : undefined;
}

export function usePerson(personID: string | undefined) {
  const { state } = useContext(ApplicationContext);
  const cacheKey = personKeys.root({ token: state.user.token, personID });
  return useQuery(cacheKey, personQuery, {
    enabled: state.user.token.length > 0 && personID !== undefined,
  });
}
