export const productFactSheetUrl =
  'https://17nl0ui4sbms.b-cdn.net/2zyaa71ihuyq-vakuutustietoasiakirja-axo-finance-maksuturva-2023-05-03.pdf?inline=true';

export const prePurchaseInformationAndTermsAndConditionsUrl =
  'https://17nl0ui4sbms.b-cdn.net/6efz5dzlsooy-etamyynnin-ennakkotiedot-ja-taydelliset-ehdot-axolaina-maksuturva-aig-2023-05-03.pdf?inline=true';

export const prePurchaseInformationUrl =
  'https://17nl0ui4sbms.b-cdn.net/679v28mz7wua-etamyynnin-ennakkotiedot-axolaina-maksuturva-aig-2023-05-03.pdf?inline=true';

export const termsAndConditionsUrl =
  'https://17nl0ui4sbms.b-cdn.net/2x5uonqaowle-vakuutusehdot-axolaina-maksuturva-2023-05-02.pdf?inline=true';

export const dataHandlingUrl =
  'https://17nl0ui4sbms.b-cdn.net/2nsb31qnisfh-2bag1gakbovr-ehdot-axolaina-turvavakuutus-2023-04-27.pdf?inline=true';
