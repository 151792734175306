export enum Status {
  Pending = 'pending',
  Completed = 'completed',
  Failed = 'failed',
}

export interface IPayment {
  ID: string;
  InsuranceID: string;
  CustomerID: string;
  MarketCountry: string;
  CompleteURL: string;
  PaymentURL: string;
  UserAgent: string;
  Amount: number;
  Status: Status;
  CreatedAt: string;
  UpdatedAt: string;
  OrderID: string;
  EmbedURL: string;
  CardNumberTruncated: string;
}

export type CreatePaymentRequest = Pick<
  IPayment,
  | 'InsuranceID'
  | 'CustomerID'
  | 'MarketCountry'
  | 'PaymentURL'
  | 'CompleteURL'
  | 'UserAgent'
  | 'Amount'
>;

export interface FindPaymentInput {
  insuranceID?: string;
  marketCountry?: string;
  customerID?: string;
}

export interface FindPaymentResponse {
  Items: IPayment[];
}
