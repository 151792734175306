import { ITokenKeyParams, tokenKeys } from './tokenKeys';

export interface IPersonKeyParams extends ITokenKeyParams {
  personID?: string;
}

export const personKeys = {
  root: (params: IPersonKeyParams) =>
    [...tokenKeys.root(params), 'person', params.personID] as const,
};

export function parsePersonKey<T extends keyof typeof personKeys>(
  queryKey: ReturnType<typeof personKeys[T]>,
) {
  const [, token, , personID] = queryKey;
  return { token, personID };
}
