import { useContext } from 'react';
import { QueryFunctionContext, useQuery } from 'react-query';
import { Status } from '../../models/insurance-payment';
import { ApplicationContext } from '../../store/context';
import { getInsurancePayment } from '../api/getInsurancePayment';
import {
  insurancePaymentKeys,
  parseInsurancePaymentKey,
} from './cache-keys/insurancePaymentKeys';

function insurancePaymentQuery({
  queryKey,
}: QueryFunctionContext<ReturnType<typeof insurancePaymentKeys['root']>>) {
  const { token, insurancePaymentID } = parseInsurancePaymentKey(queryKey);
  return getInsurancePayment(token, insurancePaymentID);
}

export function useInsurancePayment(id: string, complete: boolean) {
  const { state } = useContext(ApplicationContext);
  const cacheKey = insurancePaymentKeys.root({
    token: state.user.token,
    insurancePaymentID: id,
  });

  return useQuery(cacheKey, insurancePaymentQuery, {
    // refetch every second until we have an EmbedURL
    refetchInterval: (data) =>
      !data?.EmbedURL || (data.Status == Status.Pending && complete)
        ? 1_000
        : false,
    keepPreviousData: true,
  });
}
