import { H3, H4 } from '@a2755/ui-components';
import classNames from 'classnames';
import { HTMLAttributes } from 'react';
import styles from './InsuranceHeading.module.css';

export function InsuranceHeading({
  className,
  ...props
}: HTMLAttributes<HTMLHeadingElement>) {
  return <H3 className={classNames(className, styles.heading)} {...props} />;
}

export function InsuranceSubHeading({
  className,
  ...props
}: HTMLAttributes<HTMLHeadingElement>) {
  return <H4 className={classNames(className, styles.subHeading)} {...props} />;
}
