import { useContext } from 'react';
import { useMutation } from 'react-query';
import { ApplicationContext } from '../../store/context';
import { createTicket } from '../api/createTicket';

export function useCreateTicket() {
  const {
    state: {
      user: { token },
    },
  } = useContext(ApplicationContext);

  return useMutation(() => createTicket(token));
}
