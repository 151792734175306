import { ContentBox, IContentBox } from '@a2755/ui-components';
import { classNames } from '../../utils/classNames';
import styles from './ValidationContentBox.module.scss';

export interface ValidationContentBoxProps extends IContentBox {
  invalid?: boolean;
}

export default function ValidationContentBox({
  invalid,
  classes,
  ...props
}: ValidationContentBoxProps) {
  return (
    <ContentBox
      classes={{
        root: classNames(classes?.root, invalid && styles.invalid),
      }}
      {...props}
    />
  );
}
