import { Button, IButton } from '@a2755/ui-components';
import classNames from 'classnames';
import classnames from 'classnames';
import { ReactNode } from 'react';
import styles from './StepNavigationButtons.module.scss';

export interface IStepNavigationButtons {
  continueButton?: IButton;
  backButton?: IButton;
  children?: ReactNode;
  classes?: IClasses;
  continueButtonText?: string;
  backButtonText?: string;
}

interface IClasses {
  root?: string;
  buttonContainer?: string;
  button?: string;
}

export const StepNavigationButtons = ({ ...props }: IStepNavigationButtons) => {
  return (
    <div className={classNames(styles.container, props.classes?.root)}>
      {props.children}
      <div
        className={classNames(styles.buttons, props.classes?.buttonContainer)}
      >
        {props.backButton && (
          <Button
            {...props.backButton}
            variant="secondary"
            classes={{
              root: classnames(
                props.backButton.classes?.root,
                styles.button,
                props.classes?.button,
              ),
            }}
            width={'standard'}
          >
            <span className={styles.backButtonText}>
              {props.backButtonText}
            </span>
          </Button>
        )}
        {props.continueButton && (
          <Button
            {...props.continueButton}
            variant="primary"
            classes={{
              root: classnames(
                props.continueButton.classes?.root,
                styles.button,
                props.classes?.button,
              ),
            }}
            width={'standard'}
          >
            <span>{props.continueButtonText}</span>
          </Button>
        )}
      </div>
    </div>
  );
};
