import {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from 'react';
import { reducer, IStepName } from './store/reducer';
import { SelectCoverage } from './steps/SelectCoverage';
import { getInitialStepContextState } from '../utils/storedState';
import { StepContext } from './store/context';
import { Explainer } from './steps/Explainer';
import { Stepper } from '@a2755/ui-components';
import { Trustpilot } from '../components/Trustpilot';
import styles from './StepProvider.module.scss';
import { VerifyPaymentCard } from './steps/VerifyPaymentCard';
import { PowerOfAttorney } from './steps/PowerOfAttorney';
import { Terms } from './steps/Terms';
import { Summary } from './steps/Summary';

function CurrentStep() {
  const { dispatch, state } = useContext(StepContext);
  const stepData = state.steps[state.selectedStep];

  useEffect(() => {
    dispatch({
      type: 'Set step data',
      scope: { parentType: stepData.name },
      payload: { state: 'active' },
    });
  }, [stepData.name, dispatch]);

  return <>{steps[state.selectedStep]}</>;
}

export function StepProvider() {
  const search = new URLSearchParams(window.location.search);
  const initialState = getInitialStepContextState(search);
  const [state, originalDispatch] = useReducer(reducer, initialState);

  const dispatch: typeof originalDispatch = useCallback(
    (action) => {
      if (process.env.NODE_ENV !== 'production') {
        console.debug('Action dispatched:', action);
      }

      originalDispatch(action);
    },
    [originalDispatch],
  );

  const getStepList = () => Object.values(state.steps);
  return (
    <StepContext.Provider value={{ state, dispatch }}>
      {!state.steps.verifyPaymentCard.complete && (
        <div className={styles.stepper}>
          <Stepper locale="fi" steps={getStepList()}></Stepper>
        </div>
      )}
      <div className={styles.content}>
        {state.steps.verifyPaymentCard.complete ? <Summary /> : <CurrentStep />}
        <div className={styles.trustpilot}>
          <Trustpilot
            businessId="5d949991dff97e00014e194b"
            templateId="53aa8807dec7e10d38f59f32"
            height="150px"
            locale="fi-FI"
            link="https://fi.trustpilot.com/review/axolaina.fi"
          />
        </div>
      </div>
    </StepContext.Provider>
  );
}

const steps: Record<IStepName, ReactNode> = {
  explainer: <Explainer />,
  selectCoverage: <SelectCoverage />,
  terms: <Terms />,
  powerOfAttorney: <PowerOfAttorney />,
  verifyPaymentCard: <VerifyPaymentCard />,
};
