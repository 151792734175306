import { InsurancePayment } from '../../models';

export async function findInsurancePayment(
  token: string,
  request: InsurancePayment.FindPaymentInput,
) {
  const url = new URL(`/insurance-payment`, BASE_URL);
  for (const [key, value] of Object.entries(request)) {
    url.searchParams.set(key, value);
  }
  const response = await fetch(url, {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  });
  if (!response.ok) {
    throw new Error(`Network response was not OK: ${response.status}`);
  }
  return (await response.json()) as InsurancePayment.FindPaymentResponse;
}

const BASE_URL = process.env.REACT_APP_API_URL;
