import { ContentBox, Hr, Icons } from '@a2755/ui-components';
import classNames from 'classnames';
import { useContext } from 'react';
import styles from './Explainer.module.scss';
import stepStyles from '../../StepProvider.module.scss';
import { StepNavigationButtons } from '../../../components/StepNavigationButtons';
import { InsuranceSubHeading } from '../../../components/Insurance/InsuranceHeading';
import { StepContext } from '../../store/context';
import { REDIRECT_URL } from '../../../utils/config';
import { getTranslation } from '../../../translations/getTranslation';
import { NavWrapper } from '../../../components/NavWrapper/NavWrapper';

export const Explainer = () => {
  const { dispatch, state } = useContext(StepContext);
  const { t } = getTranslation();

  const {
    steps: { explainer: stepData },
  } = state;

  if (!['completed', 'active'].includes(stepData.state)) {
    dispatch({
      type: 'Set step data',
      scope: { parentType: stepData.name },
      payload: { ...stepData, state: 'active' },
    });
  }
  function handleNext() {
    dispatch({
      type: 'Set step data',
      scope: { parentType: stepData.name },
      payload: { ...stepData, state: 'completed' },
    });
    dispatch({
      type: 'Set step',
      scope: { parentType: 'step' },
      payload: 'selectCoverage',
    });
  }

  function handleDeclineInsurance() {
    window.location.href = REDIRECT_URL;
  }

  return (
    <div>
      <div>
        <InsuranceSubHeading>{stepData.title}</InsuranceSubHeading>
        <ContentBox
          tagText={t('Popular').toString()}
          classes={{
            root: classNames(stepStyles.contentContainer),
            tag: styles.recommendedTag,
          }}
        >
          <div className={styles.shield}>
            <Icons.Shield size="lg" />
            <h3 className={styles.productHeader}>{t('Axo Laina Insurance')}</h3>
          </div>
          <Hr className={stepStyles.horizontalSeperator} />
          <div className={styles.content}>
            <p>{t('Before you proceed, would you like to add insurance')}</p>

            <ul>
              <li className={styles.listContent}>
                <Icons.Lifesaver size="sm" />
                {t(
                  'Take care of your payments with peace of mind in an emergency',
                )}
              </li>
              <li className={styles.listContent}>
                <Icons.ShieldOutline size="sm" />
                {t(
                  'Keep your finances stable when unemployment hits or you get sick',
                )}
              </li>
              <li className={styles.listContent}>
                <Icons.Money size="sm" />
                {t('The compensation is paid directly to your account')}
              </li>
              <li className={styles.listContent}>
                <Icons.Globe size="sm" />
                {t(
                  'Ensure insurance is valid 24 hours a day and around the world for the first 12 months',
                )}
              </li>
              <li className={styles.listContent}>
                <Icons.Reload size="sm" />
                {t(
                  'To change the amount of insurance claim or terminate your insurance at any time',
                )}
              </li>
              <li className={styles.listContent}>
                <Icons.Emergency size="sm" />
                {t(
                  'Our Maksuturva insurance covers a lump sum in the event of death',
                )}
              </li>
            </ul>
          </div>
        </ContentBox>
      </div>
      <NavWrapper>
        <StepNavigationButtons
          classes={{
            buttonContainer: styles.buttonContainer,
          }}
          continueButtonText={t('Next').toString()}
          backButtonText={t('Maybe later').toString()}
          backButton={{
            width: 'standard',
            onClick: () => handleDeclineInsurance(),
            classes: { root: styles.button },
          }}
          continueButton={{
            width: 'full-width',
            onClick: () => handleNext(),
            classes: { root: styles.button },
          }}
        />
      </NavWrapper>
    </div>
  );
};
