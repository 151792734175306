import { InsuranceRequest } from '../../models';
import { API_URL } from '../../utils/config';

export async function createInsuranceRequest(
  token: string,
  create: InsuranceRequest.ICreate,
) {
  const url = new URL(`/insurance-request`, API_URL);
  const response = await fetch(url, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify(create),
  });
  if (!response.ok) {
    throw new Error(`Network response was not OK: ${response.status}`);
  }
  return (await response.json()) as InsuranceRequest.IRequest;
}
