import { A, H5, Hr, Icons, P } from '@a2755/ui-components';
import classNames from 'classnames';
import { useContext } from 'react';
import styles from './Summary.module.scss';
import stepStyles from '../../StepProvider.module.scss';
import { StepNavigationButtons } from '../../../components/StepNavigationButtons';
import { StepContext } from '../../store/context';
import { REDIRECT_URL } from '../../../utils/config';
import { getTranslation } from '../../../translations/getTranslation';
import { NavWrapper } from '../../../components/NavWrapper/NavWrapper';
import { currencyFormatter } from '../../../utils/currency';
import {
  prePurchaseInformationUrl,
  productFactSheetUrl,
} from '../../../consts/InsuranceLinks';

export const Summary = () => {
  const { state } = useContext(StepContext);
  const { t } = getTranslation();

  function handleNext() {
    window.location.href = REDIRECT_URL;
  }

  return (
    <div>
      <div className={classNames(styles.contentMargin)}>
        <div className={styles.content}>
          <div className={styles.shield}>
            <Icons.Shield size="md" />
            <h3 className={styles.thankYou}>{t('Thank you')}!</h3>
          </div>
          {t('Congratulations with your insurance')}
        </div>
        <div className={styles.infoBox}>
          <div className={styles.infoHeader}>
            <H5>{t('Insurance information')}</H5>
          </div>
          <Hr className={stepStyles.horizontalSeperator} />
          <div className={styles.infoItem}>
            <span className={styles.value}>
              {t('Will be sent to you by email')}
            </span>
          </div>
          <Hr className={stepStyles.horizontalSeperator} />
          <div className={styles.infoItem}>
            <P className={styles.label}>
              {t('Payable in the event of damage')}
            </P>
            <span className={styles.value}>
              {currencyFormatter(state.steps.selectCoverage.insurance.payout)}
            </span>
          </div>
          <Hr className={stepStyles.horizontalSeperator} />
          <div className={styles.infoItem}>
            <P className={styles.label}>{t('Monthly cost')}</P>
            <span className={styles.value}>
              {currencyFormatter(state.steps.selectCoverage.insurance.premium)}
            </span>
          </div>
          <Hr className={stepStyles.horizontalSeperator} />
          <div className={styles.extraInfo}>
            <p className={styles.label}>
              {t('Detailed information on insurance')}
            </p>
            <div className={styles.extraInfoLinkContainer}>
              <div className={styles.extraInfoLink}>
                <Icons.Pdf size="sm" />
                <A href={productFactSheetUrl} target="_blank" rel="noreferrer">
                  {t('Product Fact Sheet')}
                </A>
              </div>
              <div className={styles.extraInfoLink}>
                <Icons.Pdf size="sm" />
                <A
                  href={prePurchaseInformationUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  {t('Terms and Conditions')}
                </A>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NavWrapper>
        <StepNavigationButtons
          classes={{
            buttonContainer: styles.buttonContainer,
          }}
          continueButtonText={t('Next').toString()}
          continueButton={{
            width: 'full-width',
            onClick: () => handleNext(),
            classes: { root: styles.button },
          }}
        />
      </NavWrapper>
    </div>
  );
};
