import { ReactNode, useContext, useEffect, useState } from 'react';
import { InsuranceRequest } from '../../models';
import { ApplicationContext } from '../../store/context';
import { PageSpinner } from '../Spinner';
import { REDIRECT_URL } from '../../utils/config';
import { useCreateInsuranceRequest } from '../../hooks/mutations/useCreateInsuranceRequest';
import { useInsuranceRequest } from '../../hooks/queries/useInsuranceRequest';

export function InsuranceProvider({ children }: { children: ReactNode }) {
  const {
    state: { application, insurancePolicyReference: insurancePolicyID },
    dispatch,
  } = useContext(ApplicationContext);
  const createInsuranceRequest = useCreateInsuranceRequest();
  const [insuranceRequestID, setInsuranceRequestID] = useState(
    createInsuranceRequest.data?.ID,
  );
  const fetchInsuranceRequest = useInsuranceRequest(insuranceRequestID);

  useEffect(() => {
    const data = fetchInsuranceRequest.data;
    if (!data) return;
    const { PolicyID, FirstName, LastName } = data;
    const insurancePolicyReference =
      PolicyID && FirstName && LastName
        ? { PolicyID, FirstName, LastName }
        : null;
    if (
      data.Status === InsuranceRequest.Status.Approved &&
      insurancePolicyReference
    ) {
      dispatch({
        type: 'Set insurance',
        scope: { parentType: 'insurance' },
        payload: insurancePolicyReference,
      });
    }
  }, [fetchInsuranceRequest.data, dispatch]);

  const { mutateAsync: doCreate } = createInsuranceRequest;

  useEffect(() => {
    async function onLoad() {
      if (application && !insurancePolicyID) {
        const create: InsuranceRequest.ICreate = {
          CustomerID: application.CustomerID,
          PersonID: application.PersonID,
          MarketCountry: application.MarketCountry,
          LoanApplicationID: application.ID,
          Source: 'insurance flow',
        };
        const key = `InsuranceRequest.${JSON.stringify(create)}.ID`;
        let requestID = sessionStorage.getItem(key);
        if (!requestID) {
          const request = await doCreate(create);
          sessionStorage.setItem(key, request.ID);
          requestID = request.ID;
        }
        setInsuranceRequestID(requestID);
      }
    }

    onLoad();
  }, [application, insurancePolicyID, doCreate]);

  const isNew =
    fetchInsuranceRequest.data?.Status == InsuranceRequest.Status.New;
  const TIMEOUT_MS = 60_000; // 1 minute
  const isOld = fetchInsuranceRequest.data
    ? +new Date() - +new Date(fetchInsuranceRequest.data?.CreatedAt) >
      TIMEOUT_MS
    : false; // 30 seconds
  const hasTimedOut = isNew && isOld;

  if (hasTimedOut) {
    window.location.href = REDIRECT_URL;
    return <PageSpinner />;
  }

  if (
    createInsuranceRequest.isLoading ||
    fetchInsuranceRequest.isLoading ||
    fetchInsuranceRequest.data?.Status == InsuranceRequest.Status.New
  ) {
    return <PageSpinner />;
  }
  if (
    createInsuranceRequest.error ||
    fetchInsuranceRequest.data?.Status == InsuranceRequest.Status.Denied
  ) {
    // Ineligible or already signed
    window.location.href = REDIRECT_URL;
    return <PageSpinner />;
  }
  if (!insurancePolicyID) return <PageSpinner />;

  return <>{children}</>;
}
