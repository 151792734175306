import { useContext } from 'react';
import { StepContext } from '../../store/context';
import { useCreateInsurancePayment } from '../../../hooks/mutations/useCreateInsurancePayment';
import { ApplicationContext } from '../../../store/context';
import { Insurance, InsurancePayment } from '../../../models';
import { create, get, store } from '../../../utils/storedState';
import { State as StepsState, reducer } from '../../store/reducer';
import { completeAction } from './completeAction';
import { State as AppState } from '../../../store/reducer';
import { Action } from '../../store/actions';

function storeState(
  stepContext: StepsState,
  applicationContext: AppState,
  paymentStateKey: string,
  completeStateKey: string,
) {
  const payStep = reducer(stepContext, {
    type: 'Set step',
    scope: { parentType: 'step' },
    payload: 'verifyPaymentCard',
  });
  store(paymentStateKey, { stepContext: payStep, applicationContext });
  store(completeStateKey, {
    stepContext: reducer(payStep, completeAction),
    applicationContext,
  });
}

function dataAction(
  payment: InsurancePayment.IPayment,
  paymentState: string,
  completeState: string,
): Action {
  return {
    type: 'Set step data',
    scope: { parentType: 'verifyPaymentCard' },
    payload: {
      payment,
      complete: false,
      paymentStateKey: paymentState,
      completeStateKey: completeState,
    },
  };
}

export function useCreateInsurancePaymentEpic() {
  const createInsurancePayment = useCreateInsurancePayment();
  const { state: applicationContext } = useContext(ApplicationContext);
  const { dispatch, state: stepsContext } = useContext(StepContext);
  const {
    steps: { selectCoverage },
  } = stepsContext;

  function storePaymentState(
    payment: InsurancePayment.IPayment,
    paymentState: string,
    completeState: string,
  ) {
    const stepDataAction = dataAction(payment, paymentState, completeState);
    dispatch(stepDataAction);
    storeState(
      reducer(stepsContext, stepDataAction),
      applicationContext,
      paymentState,
      completeState,
    );
  }

  async function createPayment(insurance: Insurance.IInsurancePolicy) {
    const { CustomerID, ID: InsuranceID, MarketCountry } = insurance;
    const { url: paymentUrl, key: paymentState } = create();
    const { url: completeUrl, key: completeState } = create();
    const payment = await createInsurancePayment.mutateAsync({
      CustomerID,
      InsuranceID,
      MarketCountry,
      CompleteURL: completeUrl,
      PaymentURL: paymentUrl,
      UserAgent: window.navigator.userAgent,
      Amount: selectCoverage.insurance.payout,
    });
    storePaymentState(payment, paymentState, completeState);
  }

  function usePayment(payment: InsurancePayment.IPayment) {
    const { key: paymentState } = get(new URL(payment.PaymentURL));
    const { key: completeState } = get(new URL(payment.CompleteURL));
    storePaymentState(payment, paymentState, completeState);
  }

  return {
    create: createPayment,
    use: usePayment,
    isLoading: createInsurancePayment.isLoading,
  };
}
