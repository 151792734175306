import { Component, ErrorInfo, ReactNode } from 'react';
import { ErrorView } from '../ErrorView';

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
}

export class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      return (
        <ErrorView
          title="Something went wrong"
          description={
            'Something has gone wrong and ACE has crashed. Please reload and contact your manager if the problem persists.'
          }
        />
      );
    }

    return this.props.children;
  }
}
