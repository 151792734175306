import jwtDecode from 'jwt-decode';
import { ILoanApplication } from '../models/loan-application';
import { IClaims, IUser } from '../models/user';
import { Action } from './actions';
import { InsuranceRequest } from '../models';

export type InsurancePolicyReference = {
  PolicyID: NonNullable<InsuranceRequest.IRequest['PolicyID']>;
  FirstName: NonNullable<InsuranceRequest.IRequest['FirstName']>;
  LastName: NonNullable<InsuranceRequest.IRequest['LastName']>;
};

export type State = {
  error: string | undefined;
  user: IUser;
  application: ILoanApplication | null;
  insurancePolicyReference: InsurancePolicyReference | null;
};

export const initialState: State = {
  error: undefined,
  user: {
    email: '',
    token: '',
    roles: [],
    clientID: '',
    customerID: '',
  },
  application: null,
  insurancePolicyReference: null,
};

export function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'Set auth': {
      const token = jwtDecode<IClaims>(action.payload.jwt);
      return {
        ...state,
        user: {
          ...state.user,
          token: action.payload.jwt,
          email: token.Email,
          clientID: token.ClientID,
          customerID: token.CustomerID,
          roles: token.Roles,
        },
        application: action.payload.application,
      };
    }
    case 'Set error':
      return { ...state, error: action.payload };
    case 'Set insurance':
      return { ...state, insurancePolicyReference: action.payload };
    default:
      return state;
  }
}
