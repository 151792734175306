import { A } from '@a2755/ui-components';
import { ReactElement } from 'react';
import { prePurchaseInformationAndTermsAndConditionsUrl } from '../consts/InsuranceLinks';

export enum Languages {
  en = 'en',
  fi = 'fi',
}

const translationsObj = {
  //general
  'Go back': () => {
    return {
      en: 'Go back',
      fi: 'Takaisin',
    };
  },
  'Something went wrong': () => {
    return {
      en: 'Something went wrong',
      fi: 'Jotain meni pieleen', // waiting for translation
    };
  },
  'Call customer service if the problem persists': () => {
    return {
      en: 'Call customer service if the problem persists.',
      fi: (
        <>
          Ongelman jatkuessa ota yhteyttä asiakaspalveluun{' '}
          <a href="tel:0942452326">09 4245 2326</a>.
        </>
      ),
    };
  },
  Retry: () => {
    return {
      en: 'Retry',
      fi: 'Yritä uudelleen', // waiting for translation
    };
  },
  Insurance: () => {
    return {
      en: 'Insurance',
      fi: 'Insurance', // waiting for translation
    };
  },
  'Axo finance: My Page': () => {
    return {
      en: 'Axo finance: Insurance',
      fi: 'Axo finance: Insurance', // waiting for translation
    };
  },
  Popular: () => {
    return {
      en: 'Popular',
      fi: 'Suositeltu',
    };
  },
  Next: () => {
    return {
      en: 'Next',
      fi: 'Eteenpäin',
    };
  },
  'To proceed, you must accept the terms': () => {
    return {
      en: 'To proceed, you must accept the terms',
      fi: 'Jatkaaksesi sinun täytyy täyttää yllä mainitut ehdot',
    };
  },

  //Explainer
  'Axo Laina Insurance': () => {
    return {
      en: 'Axo Laina Insurance',
      fi: 'Axolaina Maksuturva',
    };
  },
  'Before you proceed, would you like to add insurance': () => {
    return {
      en: 'Before you proceed, would you like to add insurance.',
      fi: 'Haluatko lisätä vakuutuksen ennen eteenpäin siirtymistä? Vakuutus auttaa sinua ylläpitämään taloudellista vakautta kattamalla lainasi kuukausimaksut yllättävissä elämäntilanteissa.',
    };
  },
  'Take care of your payments with peace of mind in an emergency': () => {
    return {
      en: 'Take care of your payments with peace of mind in an emergency',
      fi: 'Säilytä taloudellinen mielenrauha myös hätätilanteissa',
    };
  },
  'Keep your finances stable when unemployment hits or you get sick': () => {
    return {
      en: 'Keep your finances stable when unemployment hits or you get sick.',
      fi: 'Pidä taloutesi vakaana työttömyyden iskiessä tai sairastuessasi',
    };
  },
  'The compensation is paid directly to your account': () => {
    return {
      en: 'The compensation is paid directly to your account',
      fi: 'Korvaus maksetaan suoraan tilillesi',
    };
  },
  'Ensure insurance is valid 24 hours a day and around the world for the first 12 months':
    () => {
      return {
        en: 'Ensure insurance is valid 24 hours a day and around the world for the first 12 months.',
        fi: 'Maksuturva on voimassa ympäri vuorokauden ja ympäri maailmaa ensimmäisen 12 kuukauden ajan',
      };
    },
  'To change the amount of insurance claim or terminate your insurance at any time':
    () => {
      return {
        en: 'To change the amount of insurance claim or terminate your insurance at any time.',
        fi: 'Voit muuttaa vakuutuskorvauksen määrää tai irtisanoa vakuutuksesi milloin tahansa',
      };
    },
  'Our Maksuturva insurance covers a lump sum in the event of death': () => {
    return {
      en: 'Our Maksuturva insurance covers a lump sum in the event of death',
      fi: 'Maksuturva-vakuutuksemme korvaa kertakorvauksen kuolemantapauksessa',
    };
  },
  'Maybe later': () => {
    return {
      en: 'Maybe later',
      fi: 'Ehkä myöhemmin',
    };
  },
  'Secure your economy': () => {
    return {
      en: 'Secure your economy',
      fi: 'Turvaa taloutesi',
    };
  },
  //SelectCoverage
  'Your insurance': () => {
    return {
      en: 'Your insurance',
      fi: 'Vakuutuksesi',
    };
  },
  'Choose your insurance level': () => {
    return {
      en: 'Choose your insurance level',
      fi: 'Vakuutusmäärä',
    };
  },
  'Payable in the event of damage': () => {
    return {
      en: 'Payable in the event of damage',
      fi: 'Maksetaan vahingon sattuessa',
    };
  },
  Month: () => {
    return {
      en: 'Month',
      fi: 'kk',
    };
  },
  'Monthly cost': () => {
    return {
      en: 'Monthly cost',
      fi: 'Kuukausimaksu',
    };
  },
  'If you are not sure of the amount of insurance, click here': () => {
    return {
      en: 'If you are not sure of the amount of insurance, click here',
      fi: 'Jos et ole varma vakuutuksen suuruudesta, klikkaa tästä',
    };
  },
  'Add together the monthly cost of your economy': () => {
    return {
      en: 'Add together the monthly cost of your economy, (eg housing, food, fuel, loans) and compare them to unemployment benefit or sickness allowance you would receive.',
      fi: 'Laske yhteen taloutesi kuukausittaiset kustannukset, (esim. asuminen, ruoka, polttoaine, lainat) ja vertaa niitä työttömyyskorvaukseen tai sairauspäivärahaan, jota saisit.',
    };
  },
  'The benefits of insurance': () => {
    return {
      en: 'The benefits of insurance',
      fi: 'Vakuutuksen edut',
    };
  },
  'You decide for yourself where you are using, for example, for rent, loan costs, heating or food':
    () => {
      return {
        en: 'You decide for yourself where you are using, for example, for rent, loan costs, heating or food.',
        fi: 'Päätät itse, mihin korvauksen käytät, esim. vuokraan, lainakuluihin, lämmitykseen tai ruokaan.',
      };
    },
  'Your insurance will follow you, not your loan': () => {
    return {
      en: 'Your insurance will follow you, not your loan. This means that your insurance will remain valid even if you repay your loan or change your loan provider.',
      fi: 'Vakuutuksesi seuraa sinua, ei lainaasi. Tämä tarkoittaa, että vakuutuksesi pysyy voimassa, vaikka maksaisit lainasi takaisin tai vaihtaisit lainantarjoajaa.',
    };
  },
  'Detailed information on insurance': () => {
    return {
      en: 'Detailed information on insurance',
      fi: 'Yksityiskohtaiset tiedot vakuutuksesta',
    };
  },
  'Do you want to take insurance': () => {
    return {
      en: 'Do you want to take insurance?',
      fi: 'Lisätäänkö vakuutus?',
    };
  },
  'No, move forward without insurance': () => {
    return {
      en: 'No, move forward without insurance',
      fi: 'Ei, siirry eteenpäin ilman vakuutusta',
    };
  },
  'Yes, continue': () => {
    return {
      en: 'Yes, continue',
      fi: 'Kyllä, jatka',
    };
  },
  'You will start receiving the compensation once the insurance is activated':
    () => {
      return {
        en: 'You will start receiving the compensation once the insurance is activated',
        fi: 'Voit saada korvausta valitsemaltasi korvaustasolta kun vakuutus astuu voimaan ja hyväksymis- ja karenssiaika ovat ohi',
      };
    },

  //Terms
  Terms: () => {
    return {
      en: 'Terms',
      fi: 'Edellytykset',
    };
  },
  '18-65 years old': () => {
    return {
      en: '18-65 years old',
      fi: 'Olen 18-65-vuotias',
    };
  },
  'I live permanently in Finland': () => {
    return {
      en: 'I live permanently in Finland and I have a valid Kela card as evidenced by the Finnish housing-based social security',
      fi: 'Asun vakituisesti Suomessa ja minulla on voimassa oleva Kela-kortti osoituksena Suomen asumisperusteiseen sosiaaliturvaan kuulumisesta',
    };
  },
  'I am on a permanent employment relationship and work at least 16 hours a week':
    () => {
      return {
        en: 'I am on a permanent employment relationship and work at least 16 hours a week',
        fi: 'Olen vakituisessa työsuhteessa ja työskentelen vähintään 16 tuntia viikossa',
      };
    },
  'I am 100 % capable and I am not aware of future lay-off or dismissal':
    () => {
      return {
        en: 'I am 100 % capable and I am not aware of future lay-off or dismissal',
        fi: 'Olen täysin työkykyinen enkä ole tietoinen tulevasta lomautuksesta tai irtisanomisesta',
      };
    },
  'I am not aware of any illness or chronic condition': () => {
    return {
      en: 'I am not aware of illness or chronic condition that can lead to sick leave.In addition, I am not aware of any future sick leave and I understand that the insurance only applies to accidents or illnesses during the insurance period',
      fi: 'En ole tietoinen mistään sairaudesta tai kroonisesta tilasta, joka voi johtaa sairauslomaan. Lisäksi en ole tietoinen mahdollisista tulevista sairauspoissaoloista ja ymmärrän, että vakuutus koskee vain vakuutuskauden aikana sattuvia tapaturmia tai sairauksia',
    };
  },
  'I will meet the above conditions': () => {
    return {
      en: 'I will meet the above conditions and confirm that I have read and agree to pre -information and complete insurance terms and conditions.',
      fi: (
        <>
          Täytän yllä mainitut ehdot ja vahvistan, että olen lukenut ja
          hyväksynyt{' '}
          <A
            href={prePurchaseInformationAndTermsAndConditionsUrl}
            target="_blank"
            rel="noreferrer"
          >
            ennakkotiedot ja täydelliset vakuutusehdot
          </A>
        </>
      ),
    };
  },
  'I understand that security insurance is valid despite': () => {
    return {
      en: 'I understand that security insurance is valid despite if I am granted a loan or not.',
      fi: 'Ymmärrän, että Maksuturva-vakuutus on voimassa huolimatta siitä, myönnetäänkö minulle lainaa tai ei.',
    };
  },
  'To proceed, you must be eligible': () => {
    return {
      en: 'To proceed, you must be eligible',
      fi: 'Jatkaaksesi sinun täytyy täyttää edellytykset',
    };
  },

  //PowerOfAttorney
  TermsOfPayment: () => {
    return {
      en: 'Terms',
      fi: 'Ehdot',
    };
  },
  'power of attorny text': (params: Params) => {
    return {
      en: (
        <>
          <p>
            {`I ${params[0]} ${params[1]}, I agree that Axo Loan may at any time charge my registered debit card to cover insurance premiums without a separate permit before each charge in accordance with the insurance certificate.`}
          </p>
          <p>
            I guarantee that I am the debit card holder I register For Axo Loan.
          </p>
          <p>
            Monthly charged amounts and insurance conditions appear in an
            insurance certificate or other notification and may vary by billing.
          </p>
          <p>
            I am responsible for updating my information to Axo Loan Changes
            when appeared.
          </p>
          <p>
            This proxy is valid from the proxy signature date for it until it is
            canceled in writing.
          </p>
        </>
      ),
      fi: (
        <>
          <p>
            {`Minä ${params[0]} ${params[1]} hyväksyn, että Axolaina voi milloin tahansa veloittaa rekisteröityä maksukorttiani vakuutusmaksujen kattamiseen ilman erillistä lupaa ennen jokaista veloitusta vakuutustodistuksen mukaisesti.`}
          </p>
          <p>
            Takaan, että olen sen maksukortin haltija, jonka rekisteröin
            Axolainalle.
          </p>
          <p>
            Kuukausittain veloitettavat summat ja vakuutuksen ehdot näkyvät
            vakuutustodistuksessa tai muussa ilmoituksessa, ja ne voivat
            vaihdella laskutuskohtaisesti.
          </p>
          <p>
            Olen vastuussa tietojeni päivittämisestä Axolainalle muutoksien
            ilmaantuessa.
          </p>
          <p>
            Tämä valtakirja on voimassa valtakirjan allekirjoituspäivästä siihen
            asti, kunnes se kirjallisesti peruutetaan.
          </p>
        </>
      ),
    };
  },
  Date: () => {
    return {
      en: 'Date',
      fi: 'Päivämäärä',
    };
  },
  'Authorized person (cardholder)': () => {
    return {
      en: 'Authorized person (cardholder)',
      fi: 'Valtakirjan antaja (kortinhaltija)',
    };
  },
  'Power of attorney': () => {
    return {
      en: 'Power of attorney:',
      fi: 'Valtakirjan vastaanottaja',
    };
  },
  'Proxy confirmation': () => {
    return {
      en: 'Proxy confirmation',
      fi: 'Valtakirjan vahvistus',
    };
  },
  'I have read and accepted the above terms and proxy': () => {
    return {
      en: 'I have read and accepted the above terms and proxy',
      fi: 'Olen lukenut ja hyväksynyt edellä mainitut ehdot ja valtakirjan',
    };
  },
  'Confirm and pay': () => {
    return {
      en: 'Confirm and pay',
      fi: 'Vahvista ja maksa',
    };
  },
  'Accept and pay': () => {
    return {
      en: 'Accept and pay',
      fi: 'Hyväksy ja maksa',
    };
  },
  // VerifyPaymentCard
  Accept: () => {
    return {
      en: 'Accept',
      fi: 'Hyväksy',
    };
  },
  //error
  'Please try again or contact support at 00000000': () => {
    return {
      en: 'Please try again or contact support at 00000000',
      fi: 'Please try again or contact support at 00000000', // waiting for translation
    };
  },
  'A technical error has occured. Please try again or contact support at 00000000':
    () => {
      return {
        en: 'A technical error has occured. Please try again or contact support at 00000000',
        fi: 'A technical error has occured. Please try again or contact support at 00000000', // waiting for translation
      };
    },

  //Summary
  'Thank you': () => {
    return {
      en: 'Thank you',
      fi: 'Kiitos',
    };
  },
  'Congratulations with your insurance': () => {
    return {
      en: (
        <>
          <p>
            Foreseeing the future is difficult. Thus protecting your economy is
            a smart choice in case your life situation suddenly changes.
          </p>
          <p>
            An email with details about the insurance will be sent to you within
            24 hours. If you have any further questions, do not hesitate to
            contact us.
          </p>
        </>
      ),
      fi: (
        <>
          <p>
            Onnittelut uudesta Axolaina Maksuturva -vakuutuksestasi. Koska
            tulevaisuuden ennustaminen on vaikeaa, on aina järkevää turvata
            taloutesi pahimman varalta.
          </p>
          <p>
            Lähetämme sinulle pian vahvistusviestin, jossa saat
            yksityiskohtaiset tiedot vakuutuksestasi. Mikäli sinulla herää
            kysymyksiä liittyen uuteen Maksuturva-vakuutukseesi, älä epäröi
            ottaa yhteyttä meihin!
          </p>
        </>
      ),
    };
  },
  'Insurance policy number': () => {
    return {
      en: 'Insurance policy number',
      fi: 'Vakuutusnumero',
    };
  },
  'Insurance information': () => {
    return {
      en: 'Insurance information',
      fi: 'Vakuutuksen tiedot',
    };
  },
  'Will be sent to you by email': () => {
    return {
      en: 'Will be sent to you by email',
      fi: 'Lähetämme sinulle pian vahvistusviestin, jossa saat yksityiskohtaiset tiedot vakuutuksestasi',
    };
  },

  //InsuranceLinks
  'Product Fact Sheet': () => {
    return {
      en: 'Product Fact Sheet',
      fi: 'Vakuutustietoasiakirja',
    };
  },
  'Pre-purchase Information and Terms and Conditions': () => {
    return {
      en: 'Pre-purchase Information and Terms and Conditions',
      fi: 'Etämyynnin ennakkotiedot ja täydelliset vakuutusehdot',
    };
  },
  'Pre-purchase Information': () => {
    return {
      en: 'Pre-purchase Information',
      fi: 'Etämyynnin ennakkotiedot',
    };
  },
  'Terms and Conditions': () => {
    return {
      en: 'Terms and Conditions',
      fi: 'Vakuutusehdot',
    };
  },
};

type Params = string[];

// Type for each record in the translator object.
type Translator<Props> = (params: Props) => {
  fi: string | ReactElement;
  en: string | ReactElement;
};

// Creates a type for each record in the translations object. The keys a the union of the keys in the
// passed in object, and the type is the translator object with the prop types set by the second type parameter.
type TranslationMap<TranslationsObject, Props> = {
  [Name in keyof TranslationsObject]: Translator<Props>;
};

function wrapTranslations<TranslationsObject>(
  translations: TranslationMap<TranslationsObject, Params>,
) {
  return translations;
}

export const translations = wrapTranslations(translationsObj);
