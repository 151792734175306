import { InsuranceRequest } from '../../models';
import { API_URL } from '../../utils/config';

export async function getInsuranceRequest(token: string, id: string) {
  const url = new URL(`/insurance-request/${id}`, API_URL);
  const response = await fetch(url, {
    method: 'GET',
    mode: 'cors',
    headers: {
      Authorization: token,
    },
  });
  if (!response.ok) {
    throw new Error(`Network response was not OK: ${response.status}`);
  }
  return (await response.json()) as InsuranceRequest.IRequest;
}
