import { useContext } from 'react';
import { useMutation } from 'react-query';
import { InsuranceRequest } from '../../models';
import { ApplicationContext } from '../../store/context';
import { createInsuranceRequest } from '../api/createInsuranceRequest';

export function useCreateInsuranceRequest() {
  const {
    state: {
      user: { token },
    },
  } = useContext(ApplicationContext);

  return useMutation((create: InsuranceRequest.ICreate) =>
    createInsuranceRequest(token, create),
  );
}
