import { Languages, translations } from './translations';

const languageEnv = process.env.REACT_APP_LANGUAGE as Languages;

export const getTranslation = () => {
  const language =
    languageEnv && Object.values(Languages).includes(languageEnv)
      ? languageEnv
      : Languages.en;
  function translate(key: keyof typeof translations, params?: string[]) {
    return translations[key](params || [])[language];
  }

  return { t: translate };
};
