import { A } from '@a2755/ui-components';
import { ReactNode, useContext, useEffect, useRef, useState } from 'react';
import { InsuranceSubHeading } from '../../../components/Insurance/InsuranceHeading';
import { useInsurancePayment } from '../../../hooks/queries/useInsurancePayment';
import { InsurancePayment } from '../../../models';
import { useCreateInsurancePaymentEpic } from './useInsurancePaymentEpic';
import { StepContext } from '../../store/context';
import { completeAction } from './completeAction';
import { IPayEx } from './IPayEx';
import styles from './VerifyPaymentCard.module.scss';
import { ApplicationContext } from '../../../store/context';
import { Spinner } from '../../../components/Spinner';
import { getTranslation } from '../../../translations/getTranslation';
import { useInsurancePolicy } from '../../../hooks/queries/useInsurancePolicy';
import { termsAndConditionsUrl } from '../../../consts/InsuranceLinks';
import { NavWrapper } from '../../../components/NavWrapper/NavWrapper';
import { StepNavigationButtons } from '../../../components/StepNavigationButtons';
import { classNames } from '../../../utils/classNames';

declare global {
  interface Window {
    payex: IPayEx | undefined;
  }
}

function VerifyPaymentEmbed({
  payment,
}: {
  payment: InsurancePayment.IPayment;
}) {
  const { dispatch } = useContext(StepContext);
  const [pollingStart, setPollingStart] = useState(new Date());
  useEffect(() => setPollingStart(new Date()), [payment.ID]);
  const { t } = getTranslation();

  const embedUrl = payment.EmbedURL;
  const tag = useRef<HTMLScriptElement | null>();

  useEffect(() => {
    function onLoad() {
      window.payex?.hostedView
        .checkout({
          container: {
            checkout: 'checkout-container',
          },
          culture: 'fi-FI',
          onPaid() {
            dispatch(completeAction);
          },
        })
        .open();
    }

    if (embedUrl && !tag.current) {
      const script = document.createElement('script');
      script.src = embedUrl;
      //script.async = true;
      script.onload = onLoad;
      document.head.appendChild(script);

      tag.current = script;
    }
  }, [embedUrl, dispatch]);

  if (!embedUrl) {
    const hasTimedOut =
      +new Date() - +pollingStart > 1_000 * 60; /* one minute */
    if (hasTimedOut) {
      return <VerifyFailed />;
    }
    return <Spinner />;
  }

  return (
    <div className={classNames(styles.verifyPaymentEmbedContainer)}>
      <div id="checkout-container"></div>
      <A href={termsAndConditionsUrl} target="_blank" rel="noreferrer">
        {t('Terms and Conditions')}
      </A>
    </div>
  );
}

function ErrorView({ message }: { message: ReactNode }) {
  const { t } = getTranslation();
  const {
    state: { insurancePolicyReference },
  } = useContext(ApplicationContext);
  const insurancePolicy = useInsurancePolicy(
    insurancePolicyReference?.PolicyID ?? undefined,
  );
  const createInsurancePayment = useCreateInsurancePaymentEpic();
  function onRetry() {
    if (insurancePolicy.data) {
      createInsurancePayment.create(insurancePolicy.data);
    } else {
      window.location.reload();
    }
  }
  return (
    <div className={styles.container}>
      <p>{message}</p>
      <a onClick={onRetry} href="#">
        {t('Retry')}
      </a>
      <p>{t('Call customer service if the problem persists')}</p>
    </div>
  );
}

function VerifyFailed() {
  const { t } = getTranslation();
  return <ErrorView message={t('Something went wrong')} />;
}

function VerifyPayment({ id }: { id: string }) {
  const {
    state: {
      steps: {
        verifyPaymentCard: { complete },
      },
    },
    dispatch,
  } = useContext(StepContext);
  const payment = useInsurancePayment(id, complete);
  const isCompleted =
    payment.data?.Status === InsurancePayment.Status.Completed;
  const isFailed = payment.data?.Status === InsurancePayment.Status.Failed;
  const isPending = payment.data?.Status === InsurancePayment.Status.Pending;
  const hasEmbed = !!payment.data?.EmbedURL;
  const TIMEOUT_MS = 60_000; // 1 minute
  const isOld = payment.data
    ? +new Date() - +new Date(payment.data.CreatedAt) > TIMEOUT_MS
    : false;
  const hasTimedOut = !hasEmbed && isOld;

  useEffect(() => {
    if (isCompleted && !complete) {
      dispatch(completeAction);
    }
  }, [isCompleted, complete, dispatch]);

  if (!payment.data) {
    return <Spinner />;
  }

  if (complete && isPending) {
    return <Spinner />;
  }

  if (hasTimedOut || isFailed) {
    return <VerifyFailed />;
  }

  if (isCompleted) {
    // StepProvider shows Summary.
    return null;
  }

  return <VerifyPaymentEmbed payment={payment.data} />;
}

function Nav() {
  const { dispatch } = useContext(StepContext);
  const { t } = getTranslation();
  function handleBack() {
    dispatch({
      type: 'Set step',
      payload: 'powerOfAttorney',
      scope: { parentType: 'step' },
    });
  }
  return (
    <NavWrapper start>
      <StepNavigationButtons
        classes={{
          buttonContainer: styles.buttonContainer,
        }}
        backButtonText={t('Go back').toString()}
        backButton={{
          width: 'full-width',
          onClick: () => handleBack(),
          classes: { root: styles.button },
        }}
      />
    </NavWrapper>
  );
}

export function VerifyPaymentCard() {
  const { state } = useContext(StepContext);

  const {
    steps: { verifyPaymentCard },
  } = state;

  const { payment } = verifyPaymentCard;

  return (
    <div>
      <InsuranceSubHeading>{verifyPaymentCard.title}</InsuranceSubHeading>
      {payment ? <VerifyPayment id={payment.ID} /> : null}
      <Nav />
    </div>
  );
}
