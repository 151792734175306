import { useContext } from 'react';
import { ApplicationContext } from '../../store/context';
import { useMutation, useQueryClient } from 'react-query';
import { InsurancePayment } from '../../models';
import { createInsurancePayment } from '../api/createInsurancePayment';
import { insuranceCustomerKeys } from '../queries/cache-keys/insuranceCustomerKeys';

export function useCreateInsurancePayment() {
  const {
    state: {
      user: { token },
    },
  } = useContext(ApplicationContext);
  const client = useQueryClient();
  return useMutation(
    (request: InsurancePayment.CreatePaymentRequest) =>
      createInsurancePayment(token, request),
    {
      onSuccess: (x) => {
        const cacheKey = insuranceCustomerKeys.root({
          token: token,
          insurancePolicyID: x.InsuranceID,
          customerID: x.CustomerID,
          marketCountry: x.MarketCountry,
        });
        client.invalidateQueries(cacheKey);
      },
    },
  );
}
