import { IStepperStep } from '@a2755/ui-components';
import {
  IPayOutAndPremium,
  payoutAndPremium,
} from '../../consts/payoutAndPremium';
import { InsurancePayment } from '../../models';
import { Action } from './actions';
import { getTranslation } from '../../translations/getTranslation';

export interface IStepState<T extends keyof State['steps']>
  extends IStepperStep {
  name: T;
}

export interface IExplainer extends IStepState<'explainer'> {
  name: 'explainer';
}

export interface ISelectCoverage extends IStepState<'selectCoverage'> {
  name: 'selectCoverage';
  insurance: IPayOutAndPremium;
}

export interface IPowerOfAttorney extends IStepperStep {
  name: 'powerOfAttorney';
  hasConfirmedPowerOfAttorney: boolean;
}

export interface ITerms extends IStepperStep {
  name: 'terms';
  hasConfirmedEligibility: boolean;
  hasConfirmedTerms: boolean;
}

export interface IConfirmPremium extends IStepperStep {
  name: 'confirmPremium';
}
export interface IVerifyPaymentCard extends IStepState<'verifyPaymentCard'> {
  name: 'verifyPaymentCard';
  payment: InsurancePayment.IPayment | null;
  paymentStateKey: string | null;
  completeStateKey: string | null;
  complete: boolean;
}

export type IStepName = keyof State['steps'];

export type IStep = State['steps'][keyof State['steps']];

export type State = {
  error: string | undefined;
  selectedStep: IStepName;
  steps: {
    explainer: IExplainer;
    selectCoverage: ISelectCoverage;
    terms: ITerms;
    powerOfAttorney: IPowerOfAttorney;
    verifyPaymentCard: IVerifyPaymentCard;
  };
};

const { t } = getTranslation();

export const initialState: State = {
  error: undefined,
  selectedStep: 'explainer',
  steps: {
    explainer: {
      name: 'explainer',
      title: t('Secure your economy').toString(),
      state: 'active',
    },
    selectCoverage: {
      name: 'selectCoverage',
      title: t('Your insurance').toString(),
      state: 'untouched',
      insurance: findCoverage(500),
    },
    terms: {
      name: 'terms',
      title: t('Terms').toString(),
      state: 'untouched',
      hasConfirmedEligibility: false,
      hasConfirmedTerms: false,
    },
    powerOfAttorney: {
      name: 'powerOfAttorney',
      title: t('TermsOfPayment').toString(),
      state: 'untouched',
      hasConfirmedPowerOfAttorney: false,
    },
    verifyPaymentCard: {
      name: 'verifyPaymentCard',
      title: t('Accept and pay').toString(),
      state: 'untouched',
      payment: null,
      paymentStateKey: null,
      completeStateKey: null,
      complete: false,
    },
  },
};

function findCoverage(payout: IPayOutAndPremium['payout']): IPayOutAndPremium {
  const coverage = payoutAndPremium.find((x) => x.payout === payout);
  if (!coverage) throw new Error(`No coverage for payout=${payout} found!`);
  return coverage;
}

export function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'Set error':
      return { ...state, error: action.payload };
    case 'Set step': {
      return {
        ...state,
        selectedStep: action.payload,
      };
    }
    case 'Set step data': {
      return {
        ...state,
        steps: {
          ...state.steps,
          [action.scope.parentType]: {
            ...state.steps[action.scope.parentType],
            ...action.payload,
          },
        },
      };
    }
    default:
      return state;
  }
}
