import { Checkbox, ContentBox } from '@a2755/ui-components';
import classNames from 'classnames';
import { useContext, useState } from 'react';
import styles from './PowerOfAttorney.module.scss';
import stepStyles from '../../StepProvider.module.scss';
import { StepNavigationButtons } from '../../../components/StepNavigationButtons';
import { InsuranceSubHeading } from '../../../components/Insurance/InsuranceHeading';
import { StepContext } from '../../store/context';
import ValidationContentBox from '../../../components/ValidationContentBox/ValidationContentBox';
import { getValidState } from '../../../utils/getValidState';
import { ApplicationContext } from '../../../store/context';
import { useCreateInsurancePaymentEpic as useInsurancePaymentEpic } from '../VerifyPaymentCard/useInsurancePaymentEpic';
import { getTranslation } from '../../../translations/getTranslation';
import { useInsurancePolicy } from '../../../hooks/queries/useInsurancePolicy';
import { usePerson } from '../../../hooks/queries/usePerson';
import { NavWrapper } from '../../../components/NavWrapper/NavWrapper';
import { useInsurancePayments } from '../../../hooks/queries/useInsurancePayments';
import { InsurancePayment } from '../../../models';

function canUsePayment(x: InsurancePayment.IPayment) {
  return (
    x.Status === InsurancePayment.Status.Pending ||
    x.Status === InsurancePayment.Status.Completed
  );
}

export const PowerOfAttorney = () => {
  const { dispatch, state } = useContext(StepContext);
  const { state: appState } = useContext(ApplicationContext);
  const insurancePolicy = useInsurancePolicy(
    appState.insurancePolicyReference?.PolicyID ?? undefined,
  );
  const person = usePerson(insurancePolicy.data?.PersonID);
  const { t } = getTranslation();

  const {
    steps: { powerOfAttorney: stepData },
  } = state;

  const [triedContinue, setTriedContinue] = useState(false);

  const insurancePayment = useInsurancePaymentEpic();
  const paymentsForPolicy = useInsurancePayments();

  function getExistingPayment() {
    const prev = state.steps.verifyPaymentCard.payment;

    if (prev && canUsePayment(prev)) {
      return prev;
    }

    const usable = (paymentsForPolicy.data?.Items ?? []).filter(canUsePayment);
    const sorted = usable.sort(
      (a, b) => +new Date(b.CreatedAt) - +new Date(a.CreatedAt),
    );

    if (sorted.length) {
      return sorted[0];
    }

    return null;
  }

  async function handleNext() {
    setTriedContinue(true);
    if (!insurancePolicy.data) return;
    if (!stepData.hasConfirmedPowerOfAttorney) return;

    const prev = getExistingPayment();

    if (prev) {
      insurancePayment.use(prev);
    } else {
      await insurancePayment.create(insurancePolicy.data);
    }

    dispatch({
      type: 'Set step data',
      scope: { parentType: stepData.name },
      payload: { ...stepData, state: 'completed' },
    });
    dispatch({
      type: 'Set step',
      scope: { parentType: 'step' },
      payload: 'verifyPaymentCard',
    });
  }

  function handleBack() {
    dispatch({
      type: 'Set step data',
      scope: { parentType: stepData.name },
      payload: { ...stepData, state: 'touched' },
    });
    dispatch({
      type: 'Set step',
      scope: { parentType: 'step' },
      payload: 'terms',
    });
  }

  const currentDate = new Date();
  const firstName = appState.insurancePolicyReference?.FirstName ?? '';
  const lastName = appState.insurancePolicyReference?.LastName ?? '';
  return (
    <div>
      <div>
        <InsuranceSubHeading>{stepData.title}</InsuranceSubHeading>
        <ContentBox
          classes={{
            root: classNames(stepStyles.contentContainer),
            tag: styles.recommendedTag,
          }}
        >
          <div className={styles.content}>
            {t('power of attorny text', [firstName, lastName])}
            <p>
              <span className={styles.label}>{t('Date')}:</span>{' '}
              {currentDate.getFullYear()} – {currentDate.getMonth() + 1} –{' '}
              {currentDate.getDate()}
            </p>
            <p>
              <span className={styles.label}>
                {t('Authorized person (cardholder)')}:
              </span>{' '}
              {firstName} {lastName}, {person.data?.SocialSecurityNumber}
            </p>
            <p>
              <span className={styles.label}>{t('Power of attorney')}:</span>{' '}
              Axo Finance Oy, 2984343-1 (“Axolaina”)
            </p>
            <ValidationContentBox
              active={stepData.hasConfirmedPowerOfAttorney}
              invalid={triedContinue && !stepData.hasConfirmedPowerOfAttorney}
            >
              <p className={styles.label}>{t('Proxy confirmation')}</p>
              <Checkbox
                name="hasConfirmedPowerOfAttorney"
                onChange={(e) =>
                  dispatch({
                    type: 'Set step data',
                    scope: { parentType: stepData.name },
                    payload: {
                      hasConfirmedPowerOfAttorney: e.target.checked,
                    },
                  })
                }
                errors={getValidState(
                  stepData.hasConfirmedPowerOfAttorney,
                  triedContinue,
                  t('To proceed, you must accept the terms').toString(),
                )}
                value={stepData.hasConfirmedPowerOfAttorney}
              >
                {t('I have read and accepted the above terms and proxy')}
              </Checkbox>
            </ValidationContentBox>
          </div>
        </ContentBox>
      </div>
      <NavWrapper>
        <StepNavigationButtons
          classes={{
            buttonContainer: styles.buttonContainer,
          }}
          backButtonText={t('Go back').toString()}
          backButton={{
            width: 'full-width',
            onClick: () => handleBack(),
            classes: { root: styles.button },
          }}
          continueButtonText={t('Confirm and pay').toString()}
          continueButton={{
            width: 'full-width',
            onClick: () => handleNext(),
            classes: { root: styles.button },
            loading: insurancePayment.isLoading,
          }}
        />
      </NavWrapper>
    </div>
  );
};
