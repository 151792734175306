import {
  CheckList,
  Slider,
  ContentBox,
  Icons,
  A,
  Tooltip,
  Hr,
} from '@a2755/ui-components';
import classNames from 'classnames';
import { useContext } from 'react';
import styles from './SelectCoverage.module.scss';
import stepStyles from '../../StepProvider.module.scss';
import { StepNavigationButtons } from '../../../components/StepNavigationButtons';
import { payoutAndPremium } from '../../../consts/payoutAndPremium';
import {
  prePurchaseInformationUrl,
  productFactSheetUrl,
} from '../../../consts/InsuranceLinks';
import { InsuranceSubHeading } from '../../../components/Insurance/InsuranceHeading';
import { StepContext } from '../../store/context';
import { currencyFormatter } from '../../../utils/currency';
import { Drawer } from '../../../components/Drawer';
import { REDIRECT_URL } from '../../../utils/config';
import { getTranslation } from '../../../translations/getTranslation';

export const SelectCoverage = () => {
  const { dispatch, state } = useContext(StepContext);
  const { t } = getTranslation();

  const {
    steps: { selectCoverage: stepData },
  } = state;

  async function handleAcceptInsurance() {
    dispatch({
      type: 'Set step data',
      scope: { parentType: 'selectCoverage' },
      payload: { state: 'completed' },
    });
    dispatch({
      type: 'Set step',
      scope: { parentType: 'step' },
      payload: 'terms',
    });
  }
  function handleDeclineInsurance() {
    window.location.href = REDIRECT_URL;
  }

  return (
    <div>
      <InsuranceSubHeading>{stepData.title}</InsuranceSubHeading>
      <ContentBox
        classes={{
          root: stepStyles.contentContainer,
        }}
      >
        <div className={styles.insuranceSummaryContainer}>
          <p className={styles.insuranceLabel}>
            {t('Choose your insurance level')}
          </p>
          <div className={styles.insuranceSummary}>
            <div
              className={classNames(styles.insuranceSummaryItem, styles.payout)}
            >
              <div className={styles.payoutLabel}>
                <p className={styles.label}>
                  {t('Payable in the event of damage')}
                </p>
                <Tooltip
                  text={t(
                    'You will start receiving the compensation once the insurance is activated',
                  ).toString()}
                  classes={{ text: styles.tooltipText }}
                >
                  <div className={styles.iconContainer}>
                    <Icons.CircleInfoOutline
                      size="xs"
                      color={{ primary: '--info-500' }}
                    />
                  </div>
                </Tooltip>
              </div>
              <p className={styles.value}>
                {currencyFormatter(stepData.insurance.payout)}/{t('Month')}
              </p>
            </div>
            <div
              className={classNames(
                styles.insuranceSummaryItem,
                styles.rightAlign,
              )}
            >
              <p className={styles.label}>{t('Monthly cost')}</p>
              <p className={styles.value}>
                {currencyFormatter(stepData.insurance.premium)}/{t('Month')}
              </p>
            </div>
          </div>
        </div>

        <div className={styles.insuranceSlider}>
          <Slider
            hideLegend
            min={payoutAndPremium[0].payout}
            max={payoutAndPremium[payoutAndPremium.length - 1].payout}
            step={100}
            value={stepData.insurance.payout}
            onChange={(value) =>
              dispatch({
                type: 'Set step data',
                scope: { parentType: 'selectCoverage' },
                payload: {
                  ...stepData,
                  insurance:
                    payoutAndPremium.find(
                      (insuranceSet) => value === insuranceSet.payout,
                    ) || stepData.insurance,
                },
              })
            }
          />
        </div>
        <Hr className={stepStyles.horizontalSeperator} />
        <Drawer
          label={t(
            'If you are not sure of the amount of insurance, click here',
          )}
          details={
            <p className={styles.drawerDetails}>
              {t('Add together the monthly cost of your economy')}
            </p>
          }
        ></Drawer>
        <Hr className={stepStyles.horizontalSeperator} />
        <Drawer
          label={t('The benefits of insurance')}
          details={
            <CheckList className={styles.checklist}>
              <CheckList.Item
                circle={true}
                key="B"
                label={t(
                  'You decide for yourself where you are using, for example, for rent, loan costs, heating or food',
                )}
                state="valid"
              />
              <CheckList.Item
                circle={true}
                key="C"
                label={t('Your insurance will follow you, not your loan')}
                state="valid"
              />
            </CheckList>
          }
        ></Drawer>
        <Hr className={stepStyles.horizontalSeperator} />
        <div className={styles.extraInfo}>
          <p className={styles.label}>
            {t('Detailed information on insurance')}
          </p>
          <div className={styles.extraInfoLinkContainer}>
            <div className={styles.extraInfoLink}>
              <Icons.Pdf size="sm" />
              <A href={productFactSheetUrl} target="_blank" rel="noreferrer">
                {t('Product Fact Sheet')}
              </A>
            </div>
            <div className={styles.extraInfoLink}>
              <Icons.Pdf size="sm" />
              <A
                href={prePurchaseInformationUrl}
                target="_blank"
                rel="noreferrer"
              >
                {t('Pre-purchase Information')}
              </A>
            </div>
          </div>
        </div>
      </ContentBox>
      <div className={styles.navWrapper}>
        <p className={classNames(styles.label, styles.navButtonsLabel)}>
          {t('Do you want to take insurance')}
        </p>
        <StepNavigationButtons
          classes={{
            root: stepStyles.buttonRoot,
            buttonContainer: classNames(
              stepStyles.buttonContainer,
              styles.buttonContainer,
            ),
            button: stepStyles.navButton,
          }}
          backButtonText={t('No, move forward without insurance').toString()}
          backButton={{
            width: 'standard',
            onClick: () => handleDeclineInsurance(),
            classes: { root: styles.button },
          }}
          continueButtonText={t('Yes, continue').toString()}
          continueButton={{
            width: 'standard',
            onClick: () => handleAcceptInsurance(),
            classes: { root: styles.button },
          }}
        />
      </div>
    </div>
  );
};
