import styles from './ErrorView.module.scss';

interface IError {
  title: string;
  description: string;
}

export function ErrorView({ title, description }: IError) {
  return (
    <div className={styles.container}>
      <h1>{title}</h1>
      <p>{description}</p>
      <button onClick={() => window.location.reload()}>Reload</button>
    </div>
  );
}
