import { tokenKeys, ITokenKeyParams } from './tokenKeys';

export interface ILoanApplicationKeyParams extends ITokenKeyParams {
  id: string;
}

export const loanApplicationKeys = {
  root: (params: ILoanApplicationKeyParams) =>
    [...tokenKeys.root(params), params.id] as const,
};

export function parseLoanApplicationKey<
  T extends keyof typeof loanApplicationKeys,
>(
  queryKey: ReturnType<typeof loanApplicationKeys[T]>,
): ILoanApplicationKeyParams {
  const [, token, insuranceRequestID] = queryKey;

  return { token, id: insuranceRequestID };
}
