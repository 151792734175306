import { useEffect } from 'react';

export const useTrustpilotScript = () => {
  useEffect(() => {
    const script = document.createElement('script');

    script.src =
      'https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js';
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
};
