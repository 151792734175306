import { QueryFunctionContext, useQuery } from 'react-query';
import {
  parseMagicTokenKey,
  magicTokenKeys,
} from './cache-keys/magicTokenKeys';
import { getLoanApplicationFromMagicToken } from '../api/getLoanApplicationFromMagicToken';
import {
  loanApplicationKeys,
  parseLoanApplicationKey,
} from './cache-keys/loanApplicationKeys';
import { getLoanApplication } from '../api/getLoanApplication';

function magicLoanApplicationQuery({
  queryKey,
}: QueryFunctionContext<
  ReturnType<typeof magicTokenKeys['magicLoanApplication']>
>) {
  return getLoanApplicationFromMagicToken(
    parseMagicTokenKey(queryKey)?.magicToken,
  );
}

function loanApplicationQuery({
  queryKey,
}: QueryFunctionContext<ReturnType<typeof loanApplicationKeys['root']>>) {
  const params = parseLoanApplicationKey(queryKey);
  return getLoanApplication(params.token, params.id);
}

export function useLoanApplicationFromMagicToken(magicToken: string | null) {
  const cacheKey = magicTokenKeys.magicLoanApplication({
    magicToken,
  });

  return useQuery(cacheKey, magicLoanApplicationQuery, {
    enabled: !!magicToken,
  });
}

export function useLoanApplication(token: string | null, id: string | null) {
  const cacheKey = loanApplicationKeys.root({
    token: token ?? '',
    id: id ?? '',
  });

  return useQuery(cacheKey, loanApplicationQuery, { enabled: !!token && !!id });
}
