import { ReactNode } from 'react';
import styles from './NavWrapper.module.scss';
import { classNames } from '../../utils/classNames';

export function NavWrapper({
  children,
  className,
  start,
}: {
  children: ReactNode;
  className?: string;
  start?: boolean;
}) {
  return (
    <div
      className={classNames(
        styles.navWrapper,
        start && styles.start,
        className,
      )}
    >
      {children}
    </div>
  );
}
