export enum Status {
  New = 'new',
  Approved = 'approved',
  Denied = 'denied',
}

export interface IRequest {
  ID: string;
  CreatedAt: string;
  UpdatedAt: string;
  CustomerID: string;
  PersonID: string;
  LoanApplicationID?: string;
  MarketCountry: string;
  Source: string;
  Status: Status;
  DenyReason?: string;
  PolicyID?: string;
  FirstName?: string;
  LastName?: string;
}

export interface ICreate {
  CustomerID: string;
  PersonID: string;
  LoanApplicationID?: string;
  MarketCountry: string;
  Source: string;
}
