import { ReactNode, useContext } from 'react';
import { ApplicationContext } from '../../store/context';
import { ErrorView } from '../ErrorView';

interface IError {
  children: ReactNode;
}

export function Error({ children }: IError) {
  const { state } = useContext(ApplicationContext);

  if (state.error !== undefined) {
    return <ErrorView title="Something went wrong" description={state.error} />;
  }

  return <>{children}</>;
}
