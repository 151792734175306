import { InsurancePayment } from '../../models';

const BASE_URL = process.env.REACT_APP_API_URL;

export async function getInsurancePayment(token: string, id: string) {
  const url = new URL(`/insurance-payment/${id}`, BASE_URL);
  const response = await fetch(url, {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  });
  if (!response.ok) {
    throw new Error(`Network response was not OK: ${response.status}`);
  }
  return (await response.json()) as InsurancePayment.IPayment;
}
