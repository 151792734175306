import { Route, Routes } from 'react-router-dom';
import './base.css';
import '@a2755/ui-components/dist/index.css';
import { Main } from './pages/Main/Main';
import { StepProvider } from './StepProvider';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Main />}>
        <Route index element={<StepProvider />} />
      </Route>
    </Routes>
  );
}

export default App;
