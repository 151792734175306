import { tokenKeys, ITokenKeyParams } from './tokenKeys';

export interface IInsurancePaymentKeyParams extends ITokenKeyParams {
  insurancePaymentID: string;
}

export const insurancePaymentKeys = {
  root: (params: IInsurancePaymentKeyParams) =>
    [...tokenKeys.root(params), params.insurancePaymentID] as const,
};

export function parseInsurancePaymentKey<
  T extends keyof typeof insurancePaymentKeys,
>(
  queryKey: ReturnType<typeof insurancePaymentKeys[T]>,
): IInsurancePaymentKeyParams {
  const [, token, insurancePaymentID] = queryKey;

  return { token, insurancePaymentID };
}
