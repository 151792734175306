import { LoadingSpinner } from '@a2755/ui-components';
import styles from './Spinner.module.css';

export function Spinner() {
  return (
    <div className={styles.spinner} role="progressbar">
      <LoadingSpinner colorVariable="--primary-500" />
    </div>
  );
}

export function PageSpinner() {
  return (
    <div className={styles.pageSpinner} role="progressbar">
      <LoadingSpinner colorVariable="--primary-500" />
    </div>
  );
}
